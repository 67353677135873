/** @format */

import React from "react";
import "./Success.css";

import googleBadge from "../../assets/google-badge.png";
import iosBadge from "../../assets/ios-badge.png";

interface SuccessProps {
  userExists?: boolean;
}

const Success = ({ userExists }: SuccessProps) => {
  return (
    <div className="success-container">
      {userExists ? (
      <>
        <h1 className="success-title">You're already registered</h1>
        <span className="success-subtitle"> If you have not downloaded the HomeTurf app, please do so by clicking on a link below.</span>
      </>
      ) : (
      <>
        <h1 className="success-title">You are Almost Done.</h1>
        <h1 className="success-title">Please Download the App Now.</h1>
      </>
      )}
      <a href="https://apps.apple.com/us/app/id1489267647" target="_blank">
        <img src={iosBadge} alt="iOS store badge" className="store-badge" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.hometurf.mobileapp.prod" target="_blank">
        <img
          src={googleBadge}
          alt="Google store badge"
          className="store-badge"
        />
      </a>
    </div>
  );
};

export { Success };
