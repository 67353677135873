/** @format */

import { config } from "../config/index";

import { phoneParams, verifyParams, userParams } from "../types";

const timeout = 10000;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  ClientId: "HomeTurfApp",
};

const authHeaders = (token: string, userId: string) => ({
  ...headers,
  Authorization: `Bearer ${token}`,
  userId,
});

function parseJSON(response: any) {
  return response.text().then((text: any) => {
    if (text) {
      return JSON.parse(text);
    }
    return {};
  });
}

async function use(url: string, headers: any, type: string, body?: any) {
  const options = {
    method: type,
    headers,
    body: JSON.stringify(body),
    timeout,
  };

  return fetch(url, options)
    .then(parseJSON)
    .then((data) => ({ data }))
    .catch((err) => ({ err }));
}

export default {
  phoneNumberSubmit: (body: phoneParams) => {
    return use(`${config.BASE_URL}/v1/api/auth/login2`, headers, "POST", body);
  },
  verifyCodeSubmit: (body: verifyParams) => {
    return use(
      `${config.BASE_URL}/v1/api/auth/login/confirm`,
      headers,
      "POST",
      body
    );
  },
  submitAccountDetails: (userId: string, token: string, body: userParams) => {
    return use(
      `${config.BASE_URL}/v1/api/users/${userId}`,
      authHeaders(token, userId),
      "PUT",
      body
    );
  },
  checkUsername: (userId: string, token: string, username: string) => {
    return use(
      `${config.BASE_URL}/v1/api/username-check/${username}`,
      authHeaders(token, userId),
      "GET"
    );
  },
  postUserAvatar: (
    authToken: string,
    userId: string,
    body: { filename: string; contentType: string }
  ) => {
    return use(
      `${config.BASE_URL}/v1/api/users/${userId}/profile-image-url`,
      authHeaders(authToken, userId),
      "POST",
      body
    );
  },
  uploadAvatarToS3: (
    filename: string,
    contentType: string,
    signedS3Url: string,
    avatarImageSource: any
  ) => {
    return use(
      signedS3Url,
      {
        "x-amz-acl": "public-read",
        "Content-Type": contentType,
      },
      "PUT",
      {
        uri: "blob:http://localhost:3001/34043d7e-febd-47ad-8cbd-948f36112357",
        type: contentType,
        name: filename,
      }
    );
  },
};
