/** @format */

import React from "react";

interface ErrorMessageParams {
  touched: boolean | undefined;
  field: string | undefined;
}

const ErrorMessage = ({ touched, field }: ErrorMessageParams) => {
  return <span className="field-error">{touched && field ? field : ""}</span>;
};

export { ErrorMessage };
