/** @format */

export const teams: {
  [k: string]: {
    teamName: string;
    seasonTicketLabel: string;
    seasonTicketEnabled: boolean;
  };
} = {
  "04aa1c9d-66da-489d-b16a-1dee3f2eec4d": {
    teamName: "Giants",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "0d855753-ea21-4953-89f9-0e20aff9eb73": {
    teamName: "Saints",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "1f6dcffb-9823-43cd-9ff4-e7a8466749b5": {
    teamName: "Chargers",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: false,
  },
  "22052ff7-c065-42ee-bc8f-c4691c50e624": {
    teamName: "Washington Football Team",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "2eff2a03-54d4-46ba-890e-2bc3925548f3": {
    teamName: "Rams",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "33405046-04ee-4058-a950-d606f8c30852": {
    teamName: "Vikings",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "386bdbf9-9eea-4869-bb9a-274b0bc66e80": {
    teamName: "Eagles",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "3d08af9e-c767-4f88-a7dc-b920c6d2b4a8": {
    teamName: "Seahawks",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "4254d319-1bc7-4f81-b4ab-b5e6f3402b69": {
    teamName: "Buccaneers",
    seasonTicketLabel: "Bucs Account ID",
    seasonTicketEnabled: true,
  },
  "4809ecb0-abd3-451d-9c4a-92a90b83ca06": {
    teamName: "Dolphins",
    seasonTicketLabel: "Member ID Number",
    seasonTicketEnabled: true,
  },
  "5fee86ae-74ab-4bdd-8416-42a9dd9964f3": {
    teamName: "Jets",
    seasonTicketLabel: "Season Ticket Acct. #",
    seasonTicketEnabled: true,
  },
  "6680d28d-d4d2-49f6-aace-5292d3ec02c2": {
    teamName: "Chiefs",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "768c92aa-75ff-4a43-bcc0-f2798c2e1724": {
    teamName: "Bills",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "7b112545-38e6-483c-a55c-96cf6ee49cb8": {
    teamName: "Bears",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "7d4fcc64-9cb5-4d1b-8e75-8a906d1e1576": {
    teamName: "Raiders",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "82cf9565-6eb9-4f01-bdbd-5aa0d472fcd9": {
    teamName: "Colts",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "82d2d380-3834-4938-835f-aec541e5ece7": {
    teamName: "Texans",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "97354895-8c77-4fd4-a860-32e62ea7382a": {
    teamName: "Patriots",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: false,
  },
  "a20471b4-a8d9-40c7-95ad-90cc30e46932": {
    teamName: "Packers",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "ad4ae08f-d808-42d5-a1e6-e9bc4e34d123": {
    teamName: "Bengals",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "c5a59daa-53a7-4de0-851f-fb12be893e9e": {
    teamName: "Lions",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "cb2f9f1f-ac67-424e-9e72-1475cb0ed398": {
    teamName: "Steelers",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "ce92bd47-93d5-4fe9-ada4-0fc681e6caa0": {
    teamName: "Broncos",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "d26a1ca5-722d-4274-8f97-c92e49c96315": {
    teamName: "Titans",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "d5a2eb42-8065-4174-ab79-0a6fa820e35e": {
    teamName: "Browns",
    seasonTicketLabel: "Season Ticket Member ID",
    seasonTicketEnabled: true,
  },
  "de760528-1dc0-416a-a978-b510d20692ff": {
    teamName: "Cardinals",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "e627eec7-bbae-4fa4-8e73-8e1d6bc5c060": {
    teamName: "Cowboys",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "e6aa13a4-0055-48a9-bc41-be28dc106929": {
    teamName: "Falcons",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "ebd87119-b331-4469-9ea6-d51fe3ce2f1c": {
    teamName: "Ravens",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "f0e724b0-4cbf-495a-be47-013907608da9": {
    teamName: "49ers",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "f14bf5cc-9a82-4a38-bc15-d39f75ed5314": {
    teamName: "Panthers",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "f7ddd7fa-0bae-4f90-bc8e-669e4d6cf2de": {
    teamName: "Jaguars",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: true,
  },
  "44169bb9-0f24-11e2-8525-18a905767e44": {
    teamName: "Red Wings",
    seasonTicketLabel: "Season Ticket Number",
    seasonTicketEnabled: false,
  },
  "441766b9-0f24-11e2-8525-18a905767e44": {
    teamName: "Islanders",
    seasonTicketLabel: "Account ID",
    seasonTicketEnabled: true,
  },
};
