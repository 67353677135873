/** @format */

import * as Yup from "yup";

const phoneValidation = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .transform((value) => value.replace(/[^\w\s]/gi, ""))
    .min(10, "Please provide 10 digit US number")
    .max(10, "Please provide 10 digit US number")
    .required("US Phone number required"),
});

const codeValidation = Yup.object().shape({
  code: Yup.string()
    .trim()
    .min(6, "Please provide a valid 6 digit code")
    .max(6, "Please provide a valid 6 digit code")
    .required("6 digit code required"),
});

const userValidation = Yup.object().shape({
  firstName: Yup.string().trim().required("First name required"),
  lastName: Yup.string().trim().required("Last name required"),
  zipCode: Yup.string()
    .trim()
    .min(5, "Please provide 5 digit zip code")
    .max(5, "Please provide 5 digit zip code")
    .required("Zip Code required"),
  emailAddress: Yup.string()
    .trim()
    .email("Please proivde valid email address")
    .required("Email address required"),
  username: Yup.string()
    .trim()
    .min(4, "Usernames must be 4 or more characters")
    .required("Username required"),
});

export { phoneValidation, codeValidation, userValidation };
