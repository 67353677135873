/** @format */

import React, { useState, useRef, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import { ReCaptcha } from "react-recaptcha-v3";
import { config } from "../../config/index";

import api from "../../api/index";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Button } from "../../components/Button";
import { codeValidation } from "../../validation";
import { FooterButtons } from "../../components/FooterButtons";

interface VerifyNumberProps {
  phoneNumber: string;
  onSuccess: (resp: any) => void;
  goBack: () => void;
}

const VerifyNumber = ({
  phoneNumber,
  onSuccess,
  goBack,
}: VerifyNumberProps) => {
  const [serverError, setServerError] = useState();
  const recaptcha = useRef<any>();
  const [token, setToken] = useState("");
  const recaptchaVerifyCallback = useCallback((recaptchaToken: string) => {
    setToken(recaptchaToken);
  }, []);

  return (
    <Formik
      initialValues={{
        phoneNumber: phoneNumber,
        code: "",
      }}
      validationSchema={codeValidation}
      validateOnChange={true}
      onSubmit={async (values) => {
        try {
          const resp: any = await api.verifyCodeSubmit(Object.assign({}, values, { source: 'web' }));

          if (resp.data.error) {
            return setServerError(resp.data.message);
          }

          onSuccess(resp);
        } catch (e) {
          setServerError(e.toString());
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="field-wrapper">
            <Field
              className="input"
              id="code"
              name="code"
              placeholder="Verification Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
            />

            <ErrorMessage
              touched={formik.touched.code}
              field={formik.errors.code}
            />
          </div>

          <Button title="Next" />
          <FooterButtons
            leftAction={goBack}
            leftButton="Back"
            rightAction={async () => {
              await recaptcha.current.execute();
              await api.phoneNumberSubmit({ phoneNumber, token });
            }}
            rightButton="Resend Code"
          />
          {!!serverError && <span className="field-error">{serverError}</span>}
          <ReCaptcha
            ref={recaptcha}
            sitekey={config.RECAPTCHA}
            action="submit_mobile_number"
            verifyCallback={recaptchaVerifyCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export { VerifyNumber };
