/** @format */

const dev = {
  BASE_URL: "http://localhost:3000",
  RECAPTCHA: "6LeJx8QZAAAAAHT2_3J3rXSsRxVa_iPNw097Xar0",
};

const prod = {
  BASE_URL: "https://api.hometurfapp.com",
  RECAPTCHA: "6LeZxsQZAAAAAPHP_tBweDmO79uUfayu10q10yg3",
};

const staging = {
  BASE_URL: "https://staging-api.hometurfapp.com",
  RECAPTCHA: "6LeZxsQZAAAAAPHP_tBweDmO79uUfayu10q10yg3",
};

const ci = {
  BASE_URL: "https://ci-api.hometurfapp.com",
  RECAPTCHA: "6LeZxsQZAAAAAPHP_tBweDmO79uUfayu10q10yg3",
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case "production":
    config = prod;
    break;
  case "staging":
    config = staging;
    break;
  case "ci":
    config = ci;
    break;
  default:
    config = dev;
}

export { config };
