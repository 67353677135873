/** @format */

import React from "react";

const Terms = () => {
  return (
    <span className="terms">
      By registering you agree to the{" "}
      <a
        target="blank"
        rel="noopener noreferrer"
        href="https://www.hometurfapp.com/terms_of_service.html"
      >
        Terms of Service
      </a>{" "}
      and the{" "}
      <a
        target="blank"
        rel="noopener noreferrer"
        href="https://www.hometurfapp.com/privacy_policy.html"
      >
        Privacy Policy
      </a>
    </span>
  );
};

export { Terms };
