/** @format */

import React from "react";
import "./FooterButtons.css";

interface FooterButtonsParams {
  leftAction?: () => void;
  leftButton?: string;
  rightAction?: () => void;
  rightButton?: string;
}

const FooterButtons = ({
  leftAction,
  leftButton,
  rightAction,
  rightButton,
}: FooterButtonsParams) => {
  return (
    <div className="footer-buttons-container">
      {leftButton && (
        <span className="footer-buttons-link" onClick={leftAction}>
          {leftButton}
        </span>
      )}
      {rightButton && (
        <span className="footer-buttons-link" onClick={rightAction}>
          {rightButton}
        </span>
      )}
    </div>
  );
};

export { FooterButtons };
