/** @format */

import React, { useState, useEffect } from "react";
import { loadReCaptcha } from "react-recaptcha-v3";
import "./App.css";
import { PhoneNumber } from "./containers/PhoneNumber";
import { VerifyNumber } from "./containers/VerifyNumber";
import { UserProfile } from "./containers/UserProfile";
import { Success } from "./containers/Success";
import { config } from "./config/index";
import headerImage from "./assets/new_header.png";
import { UserProfileParams } from "./types";
import { teams } from "./config";

enum step {
  PHONE_NUMBER = 0,
  VERIFY_CODE = 1,
  USER_PROFILE = 2,
  NEW_ACCOUNT = 3,
  EXISTING_ACCOUNT = 4,
}

const teamName = teams[window.location.search.replace("?", "")]?.teamName;

const isInputStep = (current: number) =>
  current !== step.NEW_ACCOUNT && current !== step.EXISTING_ACCOUNT;

function App() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userProfile, setUserProfile] = useState<UserProfileParams>();
  const [authToken, setAuthToken] = useState("");
  const [formStep, setFormStep] = useState(step.PHONE_NUMBER);

  useEffect(() => {
    loadReCaptcha(config.RECAPTCHA);
  }, []);

  const renderFormStep = (formStep: number) => {
    switch (formStep) {
      case step.PHONE_NUMBER:
        return (
          <PhoneNumber
            onSuccess={(number: string) => {
              setPhoneNumber(number);
              setFormStep(1);
            }}
          />
        );
      case step.VERIFY_CODE:
        return (
          <VerifyNumber
            phoneNumber={phoneNumber}
            goBack={() => setFormStep(0)}
            onSuccess={(val) => {
              const {
                data: { user, authToken },
              } = val;
              setAuthToken(authToken);
              setUserProfile((prevState: any) => ({ ...prevState, user }));

              if ("isNewUser" in user) {
                setFormStep(2);
              } else {
                setFormStep(4);
              }
            }}
          />
        );
      case step.USER_PROFILE:
        return (
          <UserProfile
            onSuccess={(resp) => {
              setFormStep(3);
            }}
            user={userProfile?.user}
            authToken={authToken}
            goBack={() => setFormStep(1)}
          />
        );
      case step.NEW_ACCOUNT:
        return <Success />;
      case step.EXISTING_ACCOUNT:
        return <Success userExists />;
    }
  };
  return (
    <div className="app">
      <div className="app-header">
        <img src={headerImage} alt="Hometurf logo and field"></img>
      </div>
      {isInputStep(formStep) && (
        <h1 className="app-title">{`Register for the ${
          teamName || "Live"
        } Arena Experience`}</h1>
      )}
      <div className="form-container">{renderFormStep(formStep)}</div>
    </div>
  );
}

export default App;
