/** @format */

import React, { useState, MouseEvent } from "react";
import { Formik, Field, Form } from "formik";
import ReactS3Uploader from "react-s3-uploader";

import { Button } from "../../components/Button";
import { ErrorMessage } from "../../components/ErrorMessage";
import api from "../../api/index";
import { userValidation } from "../../validation";
import { FooterButtons } from "../../components/FooterButtons";
import { generateUUID } from "../../utils";
import { teams } from "../../config";

const MIN_USERNAME_LENGTH = 4;

interface UserProfileProps {
  onSuccess: (resp: any) => void;
  authToken: string;
  user: any;
  goBack: () => void;
}

const UserProfile = ({
  onSuccess,
  user,
  authToken,
  goBack,
}: UserProfileProps) => {
  const [serverError, setServerError] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState<
    boolean | undefined
  >();
  const [photoUrl, setPhotoUrl] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();

  const seasonTicketLabel =
    teams[window.location.search.replace("?", "")]?.seasonTicketLabel;

  const seasonTicketEnabled =
    teams[window.location.search.replace("?", "")]?.seasonTicketEnabled;

  const handleUsernameCheck = async (input: string) => {
    const isMinLength = input.length >= MIN_USERNAME_LENGTH;
    !isMinLength && setUsernameAvailable(undefined);
    try {
      const resp: any = await api.checkUsername(user.id, authToken, input);

      if (resp.data.error) {
        return isMinLength && setUsernameAvailable(false);
      }
      isMinLength && setUsernameAvailable(true);
    } catch (e) {
      isMinLength && setUsernameAvailable(false);
    }
  };

  async function getSignedUrl(file: any, callback: any) {
    const params = {
      filename: generateUUID(),
      contentType: file.type,
    };

    setFileName(file.name);

    const { data, err }: any = await api.postUserAvatar(
      authToken,
      user.id,
      params
    );

    if (err) {
      setServerError(err.toString());
      return;
    }

    callback({ signedUrl: data });
  }

  const hasSeasonTicketError = (ticketNumber: string) => {
    const seasonTicketErrors = [
      `Ticket ${ticketNumber} is invalid.`,
      `Unable to assign ticket ${ticketNumber} to user ${user.id}.`,
      `Ticket ${ticketNumber} has already been redeemed.`,
    ];

    return seasonTicketErrors.includes(serverError);
  };

  const setSubmitBody = (values: any) => {
    let body;

    if (values.ticketNumber) {
      body = Object.assign(
        {},
        {
          ...user,
          seasonTickets: [
            {
              teamId: window.location.search.replace("?", "") || "",
              ticketNumber: values.ticketNumber,
            },
          ],
          profilePicPath: photoUrl,
        },
        values
      );
    } else {
      const { ticketNumber, ...others } = values;
      body = Object.assign(
        {},
        {
          ...user,
          profilePicPath: photoUrl,
        },
        others
      );
    }

    return body;
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        username: "",
        emailAddress: "",
        zipCode: "",
        ticketNumber: "",
        homeTurfs: window.location.search.length
          ? [window.location.search.replace("?", "")]
          : [],
      }}
      validationSchema={userValidation}
      validateOnChange={true}
      onSubmit={async (values) => {
        const body = setSubmitBody(values);

        try {
          const resp: any = await api.submitAccountDetails(
            user.id,
            authToken,
            body
          );

          if (resp.data.error) {
            return setServerError(resp.data.message);
          }

          onSuccess(resp);
        } catch (e) {
          setServerError(e.toString());
        }
      }}
    >
      {(formik) => (
        <Form>
          <span className="form-header">
            Now we just need a few more details
          </span>
          <div className="field-wrapper">
            <Field
              className="input"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />

            <ErrorMessage
              touched={formik.touched.firstName}
              field={formik.errors.firstName}
            />
          </div>
          <div className="field-wrapper">
            <Field
              className="input"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />

            <ErrorMessage
              touched={formik.touched.lastName}
              field={formik.errors.lastName}
            />
          </div>
          <div className="field-wrapper">
            <Field
              className="input"
              id="username"
              name="username"
              placeholder="Username"
              onChange={formik.handleChange}
              onBlur={(e: MouseEvent<HTMLButtonElement>) => {
                formik.handleBlur(e);
                handleUsernameCheck(formik.values.username);
              }}
              value={formik.values.username}
            />
            {usernameAvailable && (
              <span className="field-icon" role="img" aria-label="checkmark">
                ✅
              </span>
            )}

            <ErrorMessage
              touched={formik.touched.username}
              field={formik.errors.username}
            />
            {formik.touched.username && usernameAvailable === false ? (
              <span className="field-error">
                Username is taken, please try another
              </span>
            ) : null}
          </div>

          <div className="field-wrapper">
            <Field
              className="input"
              id="emailAddress"
              name="emailAddress"
              placeholder="Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailAddress}
            />

            <ErrorMessage
              touched={formik.touched.emailAddress}
              field={formik.errors.emailAddress}
            />
          </div>
          <div className="field-wrapper">
            <Field
              className="input"
              id="zipCode"
              name="zipCode"
              placeholder="Zip Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zipCode}
            />

            <ErrorMessage
              touched={formik.touched.zipCode}
              field={formik.errors.zipCode}
            />
          </div>
          {seasonTicketEnabled && (
            <div className="field-wrapper">
              <Field
                className="input"
                id="ticketNumber"
                name="ticketNumber"
                placeholder={seasonTicketLabel + " (optional)"}
                onChange={(el: any) => {
                  formik.handleChange(el);
                  hasSeasonTicketError(formik.values.ticketNumber) &&
                    setServerError("");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.ticketNumber}
              />
              {hasSeasonTicketError(formik.values.ticketNumber) && (
                <span className="field-icon" role="img" aria-label="error">
                  ❌
                </span>
              )}
              {hasSeasonTicketError(formik.values.ticketNumber) ? (
                <span className="field-error">{serverError}</span>
              ) : (
                <span className="helper-text">
                  {`If you have a ${
                    seasonTicketLabel?.toLowerCase() + " enter it above."
                  }`}
                </span>
              )}
            </div>
          )}
          <div className="field-wrapper">
            <label className="upload-wrapper">
              <input
                className="input"
                placeholder={fileName || "Profile Picture"}
              />

              {!photoUrl && <span className="upload">Upload</span>}

              <ReactS3Uploader
                getSignedUrl={getSignedUrl}
                accept="image/*"
                className="input hidden-input"
                onFinish={(e) => {
                  setPhotoUrl(e.signedUrl.split("?")[0]);
                }}
                uploadRequestHeaders={{
                  "x-amz-acl": "public-read",
                }}
                contentDisposition="auto"
              />
            </label>
          </div>
          <div className="field-wrapper">
            <label className="checkbox-container">
              Are you over 21?
              <Field type="checkbox" name="over21" />
              <span className="checkmark" />
            </label>
          </div>
          <Button title="Submit" />
          <FooterButtons leftAction={goBack} leftButton="Back" />
          {!!serverError &&
            !hasSeasonTicketError(formik.values.ticketNumber) && (
              <span className="field-error">{serverError}</span>
            )}
        </Form>
      )}
    </Formik>
  );
};

export { UserProfile };
