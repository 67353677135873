/** @format */

import React, { useRef, useState, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import { ReCaptcha } from "react-recaptcha-v3";
import { config } from "../../config/index";

import api from "../../api/index";
import { Button } from "../../components/Button";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Terms } from "../../components/Terms";
import { phoneValidation } from "../../validation";

interface PhoneNumberProps {
  onSuccess: (phoneNumber: string) => void;
}

const PhoneNumber = ({ onSuccess }: PhoneNumberProps) => {
  const recaptcha = useRef<any>();
  const [token, setToken] = useState("");
  const [serverError, setServerError] = useState();

  const recaptchaVerifyCallback = useCallback((recaptchaToken: string) => {
    setToken(recaptchaToken);
  }, []);

  return (
    <Formik
      initialValues={{
        phoneNumber: "",
      }}
      validationSchema={phoneValidation}
      validateOnChange={true}
      onSubmit={async (values) => {
        try {
          await recaptcha.current.execute();
          const { err }: any = await api.phoneNumberSubmit(
            Object.assign({}, values, { token })
          );
          if (err) {
            return setServerError(err.message);
          }
          onSuccess(values.phoneNumber);
        } catch (e) {
          return setServerError(e.message);
        }
      }}
    >
      {(formik) => (
        <Form>
          <span className="form-header">
            Enter your mobile number to get started
          </span>
          <span className="form-note">
            If you're outside the United States, please download the HomeTurf app and complete your registration there.
          </span>

          <div className="field-wrapper">
            <Field
              className="input"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="US Mobile Phone Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />

            <ErrorMessage
              touched={formik.touched.phoneNumber}
              field={formik.errors.phoneNumber}
            />
          </div>

          <Button title="Next" />
          {!!serverError && <span className="field-error">{serverError}</span>}
          <Terms />
          <ReCaptcha
            ref={recaptcha}
            sitekey={config.RECAPTCHA}
            action="submit_mobile_number"
            verifyCallback={recaptchaVerifyCallback}
          />
        </Form>
      )}
    </Formik>
  );
};

export { PhoneNumber };
