/** @format */

import React from "react";
import "./Button.css";

interface ButtonProps {
  title: string;
  onClick?: (evt: any) => void;
  disabled?: boolean;
}

const Button = ({ title, onClick, disabled }: ButtonProps) => {
  return (
    <button
      className="button"
      onClick={onClick}
      type="submit"
      disabled={disabled}
    >
      <span className="title">{title}</span>
    </button>
  );
};

export { Button };
